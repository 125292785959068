import { Injectable } from '@angular/core';
import { Http } from './shared/http-client/http-client.component';
import 'rxjs/add/operator/map';
import { Observable } from "rxjs/Observable";
import { EnvironmentSpecificService } from "../environment-specific.service";
import { AppSocket } from '../app.module';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from "@angular/router";
import { take } from 'rxjs/operators';
import { NbDialogService } from '@nebular/theme';
import { DialogConfirmComponent } from './shared/modals/dialog-confirm.component';
import { DialogVerifyLiveviewRequest } from './shared/modals/dialog-verify-liveview-request';

declare var $: any;
@Injectable()
export class PagesServices {

    apiUrl:any;
    appUrl:any;
    mapConfig:any;
    developmentEnv:boolean = true;
    account_id:any;
    deviceUsers:any = [];
    zoom: number = 14;
    lat: number = 13.736717;
    lng: number = 100.523186;
    SOSusers:any = [];
    SOSinterval:any;
    defaultMins: number = 5000;
    positionBtmRight:"toast-bottom-right";
    private userId:any;
    private userUUId:any;
    dataActivity:any = [];
    listgeofenceactivites:any = [];
    _audioSOS:any;
    _audioGun:any;
    _digitalAlarm:any;
    constructor(private _http: Http,
        private _router: Router,
        private _envService: EnvironmentSpecificService,
        private _appSocket: AppSocket,
        private _toastr: ToastrService,
        private _dialogService: NbDialogService) {
            
        this.apiUrl = this._envService.envSpecific.apiUrl;
        this.appUrl = this._envService.envSpecific.appUrl;
        this.mapConfig = this._envService.envSpecific.mapConfig;
        this.developmentEnv = this._envService.envSpecific.developmentEnv;
        
        if(this.deviceUsers.length == 0) this.ListUsers();
        this.userId = JSON.parse(localStorage.getItem("currentUser")).username;
        this.userUUId = JSON.parse(localStorage.getItem("currentUser")).id;
        this._audioSOS = new Audio("assets/audio/police-siren-sound.mp3");
        this._audioGun = new Audio("assets/audio/gun-shot.mp3");
        this._digitalAlarm = new Audio("assets/audio/digital-alarm.mp3");
    }

    GetUserId(){
        return JSON.parse(localStorage.getItem("currentUser")).username;
    }
    GetUserUUId(){
        return JSON.parse(localStorage.getItem("currentUser")).id;
    }
    ConnectStatusOfUsers = () => {
        return Observable.create((observer) => {
            this.AttachListener(observer); 
        });
    }

    AttachListener(observer){
        var that = this;
        if (this._appSocket.ioSocket._callbacks && this._appSocket.ioSocket._callbacks['$connect']) this._appSocket.removeListener('connect');
                this._appSocket.on("connect", () => {
             
                    observer.next({type:'connect'});
                });

                this._appSocket.on('disconnect', function() {

                    var data = JSON.parse(localStorage.getItem("currentUser"));
                    var logoutProcess =  localStorage.getItem("logout");
          
                    if(data != null && logoutProcess == null){
                      localStorage.setItem("logout","true");
                      setTimeout(() => {
                        localStorage.removeItem("logout");
                      }, 3000);
          
                      that.DisconnectWebSocket();
                    }

                    setTimeout(() => {
                        that._router.navigate(["/login"]).then(()=>window.location.reload());
                    }, 3000);
        
                });

             
                if (this._appSocket.ioSocket._callbacks['$online'])  this._appSocket.removeListener('online');
                this._appSocket.on('online', (user) => {
                    
                    var device = this.deviceUsers.filter(q=>q.device == user)[0];

                    // if(user == this.userId){
                    //     setTimeout(() => {
                    //         that._router.navigate(["/login"]).then(()=>window.location.reload());
                    //     }, 3000);
                    // }
                    
                    this._toastr.success(device.firstname+" ("+user +") is Online",'', {  positionClass: 'toast-bottom-right' });
                    observer.next({type:'online',user:user});
                });

                if (this._appSocket.ioSocket._callbacks['$offline'])  this._appSocket.removeListener('offline');
                this._appSocket.on('offline', (user) => {

                    var sos = this.SOSusers.filter(q=>q.user == user)[0];
                    if(sos){
                        this._toastr.remove(sos.toast.toastId);
                        clearTimeout(sos.timeout);

                        this.SOSusers = this.SOSusers.filter(q=>q.user != sos.user);
                    }

                    var device = this.deviceUsers.filter(q=>q.device == user)[0];
                    this._toastr.error(device.firstname+" ("+user +") is Offline",'', {  positionClass: 'toast-bottom-right' });

                    if(device.verification != null){
                        var data = JSON.parse(JSON.stringify(device.verification));
                        device.verification = null;
                        if( $(".toast-live-request"+data.user).parents(".toast-container").find(".toast-close-button") != null){
                            this._toastr.error("Live view request has been terminated.");
                            $(".toast-live-request"+data.user).parents(".toast-container").find(".toast-close-button").click();
                            this._appSocket.emit("CancelRequestToLive",JSON.stringify(data));
                        }

                        if($("#btnClose"+device.user) != null){
                            $("#btnClose"+device.user).click();
                            this._toastr.error("Live view request has been terminated.");
                            this._appSocket.emit("CancelRequestToLive",JSON.stringify(data));
                        }
                    }
                    var url = window.location.href;
                    if(url.indexOf(device.id)>-1 && url.indexOf('/devices/manage-device')>-1){
                        this._router.navigate(['/pages', 'devices']);
                    }

                    if($("#btnClose"+user) != null){
                        $("#btnClose"+user).click();
                    }

                    if($(".toast-live-request"+user)!= null && $(".toast-live-request"+user).length > 0){
                        $(".toast-live-request"+user).parents(".toast-container").find(".toast-close-button").click();
                    }

                    if($(".toast-share-live"+user)!= null && $(".toast-share-live"+user).length > 0){
                        $(".toast-share-live"+user).parents(".toast-container").find(".toast-close-button").click();
                    }

                    observer.next({type:'offline',user:user});
                }); 

                if (this._appSocket.ioSocket._callbacks['$connect_error'])  this._appSocket.removeListener('connect_error');
                this._appSocket.on("connect_error", (err) => {
          
                    console.log(err.message);
                });

                if (this._appSocket.ioSocket._callbacks['$RequestSOS'])  this._appSocket.removeListener('RequestSOS');
                this._appSocket.on("requestSOS", (user) => {

                    var device = this.deviceUsers.filter(q=>q.device == user)[0];
                    this._toastr.warning("<img class='toast-sos' src='./assets/images/alert-siren.gif'/>"+ device.firstname+"<br/>("+ device.device+")","SOS ALERT"
                    , {  positionClass: 'toast-bottom-right'
                    ,timeOut: this.defaultMins
                    ,disableTimeOut	: true
                    ,tapToDismiss: true
                    ,enableHtml: true }).onTap
                    .pipe(take(1))
                    .subscribe(() => this.ShowSOSLiveview(device.device));
                    
                    this._audioSOS.play();
                    setTimeout(() => {
                        $(".toast-sos").parents(".toast-container").find(".toast-warning").each(function(){  $(this).css("background-image","none"); });
                      }, 100);
                    observer.next({type:'sos',user:user});
                });

                if (this._appSocket.ioSocket._callbacks['$requestHolsterTrigger'])  this._appSocket.removeListener('requestHolsterTrigger');
                this._appSocket.on("requestHolsterTrigger", (user) => {

                    var device = this.deviceUsers.filter(q=>q.device == user)[0];
                    this._toastr.warning("<img class='toast-sos' src='./assets/images/holster-sensor-trigger.gif'/>"+ device.firstname+"<br/>("+ device.device+")","GUN ALERT"
                    , {  positionClass: 'toast-bottom-right'
                    ,timeOut: this.defaultMins
                    ,disableTimeOut	: true
                    ,tapToDismiss: true
                    ,enableHtml: true }).onTap
                    .pipe(take(1))
                    .subscribe(() => this.ShowSOSLiveview(device.device));
         
                    this._audioGun.play();
                    setTimeout(() => {
                        $(".toast-sos").parents(".toast-container").find(".toast-warning").each(function(){  $(this).css("background-image","none"); });
                      }, 100);
                    observer.next({type:'sos',user:user});
                });

                if (this._appSocket.ioSocket._callbacks['$requestlowbatterynotification'])  this._appSocket.removeListener('requestlowbatterynotification');
                this._appSocket.on("requestlowbatterynotification", (user) => {
                    var device = this.deviceUsers.filter(q=>q.device == user)[0];
                    this._toastr.error("<img class='toast-low-battery' src='./assets/images/low-battery-level.png'/>"+ device.firstname+"<br/>("+ device.device+")","BATTERY LOW"
                    , {  positionClass: 'toast-bottom-right'
                    ,enableHtml: true });
                    setTimeout(() => {
                        $(".toast-low-battery").parents(".toast-container").find(".toast-error").each(function(){  $(this).css("background-image","none"); });
                    }, 100);
                });

                
                if (this._appSocket.ioSocket._callbacks['$LiveviewVerification'])  this._appSocket.removeListener('LiveviewVerification');
                this._appSocket.on("LiveviewVerification", (message) => {
                    var that = this;
                    var data = JSON.parse(message);
                    var device = this.deviceUsers.filter(q=>q.device == data.user)[0];
                    var camera = this.deviceUsers.filter(q=>q.device == data.device)[0];
                    if(camera.status){
                        device.verification = data;
                        this._toastr.info("<img class='toast-live-request toast-live-request"+device.device+" toast-live-request"+data.device+"' src='./assets/images/live-stream.png'/>"+ device.firstname+"<br/>("+ device.device+")","Live View Request"
                        , {  positionClass: 'toast-bottom-right'
                        ,timeOut: this.defaultMins
                        ,disableTimeOut	: true
                        ,tapToDismiss: true
                        ,enableHtml: true }).onTap
                        .pipe(take(1))
                        .subscribe(() => this.ShowVerificationLiveview(data));
                        setTimeout(() => {
                            $(".toast-live-request").parents(".toast-container").find(".toast-info").each(function(){  $(this).css("background-image","none"); });
                            $(".toast-live-request"+data.user).parents(".toast-container").find(".toast-close-button")[0].addEventListener('click', function() {
                                if(device.verification!= null){
                                    device.verification.verify = false;
                                    that._appSocket.emit("VerifyRequestToLive",JSON.stringify(device.verification));
                                    that._toastr.success("Live view request has been rejected successfully.");
                                    device.verification.verification = null;
                                }
                            });
                          }, 100);
                    }
                });

                if (this._appSocket.ioSocket._callbacks['$CancelLiveviewVerification'])  this._appSocket.removeListener('CancelLiveviewVerification');
                this._appSocket.on('CancelLiveviewVerification', (message) => {
                    var data = JSON.parse(message);
                    var device = this.deviceUsers.filter(q=>q.device == data.user)[0];
                    if(device.verification != null){
                        device.verification = null;
                        if( $(".toast-live-request"+data.user) != null){
                            $(".toast-live-request"+data.user).parents(".toast-container").find(".toast-close-button").click();
                        }
                        if(data.message == "CANCEL"){
                            this._toastr.warning("Live view request has been cancelled.");
                        }if(data.message == "EXIT"){
                            this._toastr.error("Live view request has been terminated.");
                        }else if(data.message == "EXPIRED"){
                            this._toastr.warning("Live view request has expired.");
                        }
                        if($("#btnClose"+data.user) != null){
                            $("#btnClose"+data.user).click();
                        }
                 
                    }
                });

                if (this._appSocket.ioSocket._callbacks['$requesttoshareliveview'])  this._appSocket.removeListener('requesttoshareliveview');
                this._appSocket.on("requesttoshareliveview", (message) => {
                    if(message && message!= ""){
                        var data = JSON.parse(message);
                        if(data){
                            var device = this.deviceUsers.filter(q=>q.device == data.device)[0];
                            var user = this.deviceUsers.filter(q=>q.device == data.user)[0];
                            var camera = this.deviceUsers.filter(q=>q.device == data.device)[0];
                            if(camera.status){
                                this._toastr.info("<img class='toast-share-live toast-share-live"+device.device+"' src='./assets/images/shared.png'/>"+ user.firstname+" ("+ user.device+")","Shared Live View"
                                    , {  positionClass: 'toast-bottom-right'
                                    ,timeOut: this.defaultMins
                                    ,disableTimeOut	: true
                                    ,tapToDismiss: true
                                    ,enableHtml: true }).onTap
                                    .pipe(take(1))
                                    .subscribe(() => this.ShowShareLiveview(device.device , message));
                                    
                                    setTimeout(() => {
                                        $(".toast-share-live").parents(".toast-container").find(".toast-info").each(function(){  $(this).css("background-image","none"); });
                                      }, 100);
                            }
                        }
                    }
                });

                if (this._appSocket.ioSocket._callbacks['$requestOfficerDown'])  this._appSocket.removeListener('requestOfficerDown');
                this._appSocket.on("requestOfficerDown", (user) => {
                      var that = this;
                      var device = this.deviceUsers.filter(q=>q.device == user)[0];
                      this._toastr.warning("<img class='toast-sos toast-officerdown"+device.device+"' src='./assets/images/officer-down.png'/>"+ device.firstname+"<br/>("+ device.device+")","OFFICER DOWN"
                      , {  positionClass: 'toast-bottom-right'
                      ,timeOut: this.defaultMins
                      ,disableTimeOut	: true
                      ,tapToDismiss: true
                      ,enableHtml: true }).onTap
                      .pipe(take(1))
                      .subscribe(() => {
                          that._digitalAlarm.pause();
                          that._digitalAlarm.currentTime = 0;
                          that.ShowSOSLiveview(device.device)
                      });
              
                      this._digitalAlarm.play();
                      setTimeout(() => {
                          $(".toast-sos").parents(".toast-container").find(".toast-warning").each(function(){  $(this).css("background-image","none"); });
                          $(".toast-officerdown"+device.device).parents(".toast-container").find(".toast-close-button")[0].addEventListener('click', function() {
                              that._digitalAlarm.pause();
                              that._digitalAlarm.currentTime = 0;
                          });
                        }, 100);
                        observer.next({type:'sos',user:user});
                });
    }

    ShowSOSLiveview(deviceId){
        if(window.location.href.includes("live-view") && window.location.href.includes("mode=EMERGENCYALERT")){
            $("#playlive"+deviceId).click();
        }else{
            this._router.routeReuseStrategy.shouldReuseRoute = () => false;
            this._router.onSameUrlNavigation = 'reload';
            this._router.navigate(["/pages","live-view",{mode:'EMERGENCYALERT',id:deviceId}]);
        }
    }


    ShowShareLiveview(deviceId , message){
        localStorage.setItem('reqplaylive', message);
        if(window.location.href.includes("live-view")){
            $("#reqplaylive"+deviceId).click();
        }else{
            this._router.routeReuseStrategy.shouldReuseRoute = () => false;
            this._router.onSameUrlNavigation = 'reload';
            this._router.navigate(["/pages","live-view",{mode:'SHARELIVEVIEW',id:deviceId}]);
        }
    }

    ShowVerificationLiveview(data){
        var device = this.deviceUsers.filter(q=>q.device == data.user)[0];
        if(device.verification != null && data.token == device.verification.token){
            this._dialogService.open(DialogVerifyLiveviewRequest, {
                context: {data: device.verification},
                closeOnBackdropClick: false,
                closeOnEsc: false
              }).onClose.subscribe(value =>{
                if(value == 'AUTHORIZE'){
                    device.verification.verify = true;
                    this._appSocket.emit("VerifyRequestToLive",JSON.stringify(device.verification));
                }else if(value == 'REJECT' || value == null){
                    device.verification.verify = false;
                    this._appSocket.emit("VerifyRequestToLive",JSON.stringify(device.verification));
                }
                device.verification = null;
            });
        }
    }

    GetResponseStream = () =>{
        return Observable.create((observer) => {

            if (this._appSocket.ioSocket._callbacks['$ResponseStartStream'])  this._appSocket.removeListener('ResponseStartStream');
            this._appSocket.on('ResponseStartStream', (message) => {
                observer.next({type:'ResponseStartStream',data: JSON.parse(message)});
            });
        });
    }

    DisconnectStatusOfUsers(){
        if(this._appSocket) this._appSocket.removeAllListeners();
    }

    GetAppGPSSocket = () => {
        return Observable.create((observer) => {
            this._appSocket.once("connect", () => {

            });

            if (this._appSocket.ioSocket._callbacks['$GPSMessage'])  this._appSocket.removeListener('GPSMessage');
            this._appSocket.on('GPSMessage', (message) => {
                observer.next({status:'GPSMessage',message:message});
            });

        });
    }

    GetAppLastLocation = () =>{
        return Observable.create((observer) => {
            if(this._appSocket){
                if (this._appSocket.ioSocket._callbacks && this._appSocket.ioSocket._callbacks['$ResponseDeviceLocation'])  this._appSocket.removeListener('ResponseDeviceLocation');
                this._appSocket.on('ResponseDeviceLocation', (message) => {
                    observer.next({status:'ResponseDeviceLocation',message:message});
                });
            }
        });
    }
    GetListOnlineUsers(): Observable<any>{
        
        return this._http.get(this.appUrl + '/api/users/listonlineusers').map((response: Response) => {
            return response;
        });
    }

    GetListUsers(): Observable<any>{
        
        return this._http.get(this.appUrl + '/api/users/listusers').map((response: Response) => {            
            return response;
        });
    }

    RequestLastLocation(user){

        this._appSocket.emit("RequestLastLocation",user);
    }

    ConnectWebSocket(){

        this._appSocket.connect();
    }

    DisconnectWebSocket(){
        if(this._appSocket){
            this._appSocket.ioSocket.removeAllListeners();
            this._appSocket.disconnect();
        }
        this._appSocket = null;
    }

    ListUsers(){
        var that = this;
        return new Promise(function (res) {
     
            that.GetListUsers().subscribe(devices=>{

            let devUsers = [];
            if(devices.status){
    
              devices.data.forEach(row => {
                var photo;
                var pinPhoto;
                if(row.photo){
                  photo = "data:image/jpeg;base64," + row.photo ;
                }else{
                  photo="assets/images/no-photo.png";
                }
                devUsers.push({
                  id:row.id,
                  name:row.name,
                  device: row.username,
                  firstname:row.firstname,
                  status: false,
                  photo: photo,
                  user_type: row.user_type
                });
              });
              that.deviceUsers = devUsers;
            }

            res(true);
          });
        });
    }

    RequestStartStreaming(id){

        this._appSocket.emit("RequestStartStreaming",id);
    }
    
    RequestStopStreaming(id){
    
        this._appSocket.emit("RequestStopStreaming",id);
    }
    
    RequestStartRealtimeGPS(id){

        this._appSocket.emit("RequestStartRealtimeGPS",id);
    }

    RequestStopRealtimeGPS(id){

        this._appSocket.emit("RequestStopRealtimeGPS",id);
    }

    GetResponseRealtimeGPS = () =>{
        return Observable.create((observer) => {

            if (this._appSocket.ioSocket._callbacks['$ResponseStartRealtimeGPSStatus'])  this._appSocket.removeListener('ResponseStartRealtimeGPSStatus');
            this._appSocket.on('ResponseStartRealtimeGPSStatus', (message) => {
                observer.next({type:'ResponseStartRealtimeGPSStatus',data: JSON.parse(message)});
            });

            if (this._appSocket.ioSocket._callbacks['$ResponseStartRealtimeGPS'])  this._appSocket.removeListener('ResponseStartRealtimeGPS');
            this._appSocket.on('ResponseStartRealtimeGPS', (message) => {
                observer.next({type:'ResponseStartRealtimeGPS',data: JSON.parse(message)});
            });
         
        });
    }

    RequestGetDeviceInfo(id){
    
        this._appSocket.emit("ReqquestDeviceInfo",id);
    }

    GetResponseDeviceInfo = () =>{
        return Observable.create((observer) => {

            if (this._appSocket.ioSocket._callbacks['$ResponseDeviceInfo'])  this._appSocket.removeListener('ResponseDeviceInfo');
            this._appSocket.on('ResponseDeviceInfo', (message) => {
                observer.next({type:'ResponseDeviceInfo',data: JSON.parse(message)});
            });
        });
    }

    GetListRemoteFiles(deviceId){
        
        this._appSocket.emit("requestlistremotefiles",JSON.stringify({device:deviceId,request:this.GetUserId()}));
    }

    GetResponseListRemoteFiles = () =>{
        return Observable.create((observer) => {

            if (this._appSocket.ioSocket._callbacks['$responselistremotefiles'])  this._appSocket.removeListener('responselistremotefiles');
            this._appSocket.on('responselistremotefiles', (message) => {
                observer.next({type:'responselistremotefiles',data: JSON.parse(message).data});
            });
        });
    }

    SendPingToDevice(deviceId){
        
        this._appSocket.emit("requestpingnotification",JSON.stringify({device:deviceId}));
    }

    DownloadfileFromDevice (deviceId,file,token){
        this._appSocket.emit("requestdownloadfiles",JSON.stringify({device:deviceId,filepath:file.location,filename:file.fileName,request:this.GetUserId(),token:token}));
    }

    GetResponseDownloadfile = () =>{
        return Observable.create((observer) => {

            if (this._appSocket.ioSocket._callbacks['$responsedownloadfiles'])  this._appSocket.removeListener('responsedownloadfiles');
            this._appSocket.on('responsedownloadfiles', (message) => {
                observer.next({type:'responsedownloadfiles', response:message});
            });
        });
    }

    GetResponseDownloadfileProgress = () =>{
        return Observable.create((observer) => {

            if (this._appSocket.ioSocket._callbacks['$responsedownloadfilesprogress'])  this._appSocket.removeListener('responsedownloadfilesprogress');
            this._appSocket.on('responsedownloadfilesprogress', (message) => {
                observer.next({type:'responsedownloadfilesprogress', response:message});
            });
        });
    }

    SendRequestzZipS3File = (deviceId,files) =>{
        return Observable.create((observer) => {
            this._appSocket.emit("requestzips3filetoken", JSON.stringify({ device: deviceId, files: files }),(res)=>{
                
                observer.next(res);
            });
        });
    }

    DisconnectGeofences(){
        if (this._appSocket.ioSocket._callbacks['$listenergeofencegps'])  this._appSocket.removeListener('listenergeofencegps');
        if (this._appSocket.ioSocket._callbacks['$listenergeofencegpsvalidation'])  this._appSocket.removeListener('listenergeofencegpsvalidation');
    }

    ListenerGeofence = () =>{
        return Observable.create((observer) => {

            if (this._appSocket.ioSocket._callbacks['$listenergeofencegps'])  this._appSocket.removeListener('listenergeofencegps');
            this._appSocket.on('listenergeofencegps', (message) => {
                observer.next({type:'listenergeofencegps',data: JSON.parse(message)});
            });
        });
    }

    ListenerGeofenceValidation = () =>{
        return Observable.create((observer) => {
          
            if (this._appSocket.ioSocket._callbacks['$listenergeofencegpsvalidation'])  this._appSocket.removeListener('listenergeofencegpsvalidation');
            this._appSocket.on('listenergeofencegpsvalidation', (message) => {

                var incommingData =  JSON.parse(message);

                if(this.GetUserUUId() === incommingData.created_by) {
                    
                    var existData = this.listgeofenceactivites.filter(q=>q.geofenceId == incommingData.geofenceId && q.deviceUUId == incommingData.deviceUUId)[0];
                    if(!existData) {
                        // Nodata
                        existData = {
                            geofenceId : incommingData.geofenceId,
                            accountId: incommingData.accountId,
                            deviceUUId : incommingData.deviceUUId,
                            name: incommingData.name,
                            enforce_type: incommingData.enforce_type,
                            shape: incommingData.shape,
                            date: incommingData.date,
                            isenter: incommingData.isenter,
                            created_by: incommingData.created_by
                        };
                        this.listgeofenceactivites.push(existData);
                        this.ShowToastValidation(existData);
                    } else {
                        // ExistData
                        if(!(existData.isenter == incommingData.isenter)){
                            existData.enforce_type = incommingData.enforce_type;
                            existData.isenter = incommingData.isenter;
                            existData.date = incommingData.date
                            this.ShowToastValidation(existData);
                        }   
                    }

                }

                observer.next({type:'listenergeofencegpsvalidation',data: JSON.parse(message)});
            });
        });
    }

    ShowToastValidation(data){

        var deviceUser = this.deviceUsers.filter(q => q.id === data.deviceUUId)[0];

        switch(data.enforce_type){
            case "ENTER":
                if(data.isenter){
                    this._toastr.warning("<img class='toast-violation' src='./assets/images/do-not-enter.png'/><span class='violation-device'>"+ deviceUser.firstname+" ("+ deviceUser.device+")</span><span class='violation-activity'> ENTER '"+ data.name +"'</span>","Geofence Violation"
                    , {  positionClass: 'toast-bottom-right'});
                }
            break;
            case "EXIT":
                if(!data.isenter){
                    this._toastr.warning("<img class='toast-violation' src='./assets/images/do-not-enter.png'/><span class='violation-device'>"+ deviceUser.firstname+" ("+ deviceUser.device+")</span><span class='violation-activity'> EXIT '"+ data.name +"'</span>","Geofence Violation"
                    , {  positionClass: 'toast-bottom-right'});
                }
            break;
            default:
            case "ALL":
                if(data.isenter) { 
                    this._toastr.warning("<img class='toast-violation' src='./assets/images/do-not-enter.png'/><span class='violation-device'>"+ deviceUser.firstname+" ("+ deviceUser.device+")</span><span class='violation-activity'> ENTER '"+ data.name +"'</span>","Geofence Violation"
                    , {  positionClass: 'toast-bottom-right'});
                } else {
                    this._toastr.warning("<img class='toast-violation' src='./assets/images/do-not-enter.png'/><span class='violation-device'>"+ deviceUser.firstname+" ("+ deviceUser.device+")</span><span class='violation-activity'> EXIT '"+ data.name +"'</span>","Geofence Violation"
                    , {  positionClass: 'toast-bottom-right'});
                }
            break;
        }
    }

    RequestUpdateDeviceLogs(device){
        this._appSocket.emit("RequestUpdateDeviceLogs",device);
    }

    GetResponseUpdateDeviceLogs = () =>{
        return Observable.create((observer) => {
            if (this._appSocket.ioSocket._callbacks['$ResponseUpdateDeviceLogs'])  this._appSocket.removeListener('ResponseUpdateDeviceLogs');
            this._appSocket.on('ResponseUpdateDeviceLogs', (message) => {
                observer.next({type:'ResponseUpdateDeviceLogs', response: JSON.parse(message)});
            });
        });
    }

    GetDeviceLogList(device): Observable<any>{
        
        return this._http.get(this.appUrl + '/api/devicelogs/getdeviceloglist/'+device).map((response: Response) => {
            return response;
        });
    }
    
    PinAuthorize(username,pincode): Observable<any> {
        let body = { 
            "username": username,
            "pincode": pincode 
        };
        return this._http.post(this.apiUrl.concat('/get-start/pinauthorize'), body).map((response: Response) => {

            return response;
        });
    }

    CheckIsDispatchOnline(data,callback){
        this._appSocket.emit("IsDispatchOnline",JSON.stringify(data),callback);
    }

    
  openPlacePicker(data) {

    const input = document.getElementById('txtMapSearch') as HTMLInputElement;
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.bindTo('bounds', data.map);
    autocomplete.setFields(['place_id', 'geometry', 'name']);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        console.error('Place not found');
        return;
      }
      data.latitude = place.geometry.location.lat();
      data.longitude = place.geometry.location.lng();
      data.zoom = 14;
      setTimeout(() => {
        data.zoom = 15;
      }, 300);
  
    });
  }

  SendResetPasswordToDevice(deviceId){
        
    this._appSocket.emit("requestresetdefaultpassword",JSON.stringify({device:deviceId}));
    }
}
